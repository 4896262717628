import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Header } from "./components/custom/components";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";

export default function App() {
  // State variables
  const [darkMode, setDarkMode] = useState(true);

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="min-h- w-full flex flex-col text-gray-700 dark:bg-gray-800 dark:text-white">
        <Router>
          <div className="flex-1 flex flex-col w-full">
            <Header
              pages={{
                Home: 0,
                "About Me": 725,
                Projects: 1420,
                Contact: document.body.scrollHeight,
              }}
              darkMode={darkMode}
              setDarkMode={setDarkMode}
            />
            <Switch>
              <Route exact path="/">
                <HomePage darkMode={darkMode} />
              </Route>
              <Route path="/">
                <NotFoundPage />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </div>
  );
}
