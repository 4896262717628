export default function Badge(props) {
  return (
    <div
      className={`rounded-full bg-${props.color ?? "gray"}-300 text-${
        props.color ?? "gray"
      }-900 py-2 px-4 text-xs transform hover:scale-110 transition hover:shadow-xl`}
    >
      {props.text}
    </div>
  );
}
