import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { ChevronDoubleDownIcon } from "@heroicons/react/solid";

import rinter from "../assets/rinter.jpg";
import fish from "../assets/fish.jpg";
import rafting1 from "../assets/rafting-1.jpg";
import rafting2 from "../assets/rafting-2.jpg";
import savage1 from "../assets/savage-race.jpg";
import savage2 from "../assets/savage-race-2.jpg";
import savage3 from "../assets/savage-race-3.jpg";
import nate from "../assets/nate-pic.jpg";
import spikeball from "../assets/spikeball.jpg";
import maddy from "../assets/maddy-pic.jpg";

import { SocialIcons } from "../components/custom/components";
import Badge from "../components/base/Badge";

export default function HomePage(props) {
  const colors = ["red", "yellow", "green", "blue", "indigo", "purple", "pink"];
  const projects = [
    {
      title: "CrowdClips",
      description: `The CrowdClips app allows fans to record short video clips of themselves and their friends at sporting
      events. These clips are then available to the stadium where they can sift through them and leverage our
      artificial intelligence structure to find the best videos based on several factors such as emotion in the
      faces of fans, movement in the clip, or just how fun they are!`,
      item: () => (
        <div>
          <iframe
            title="CrowdClips"
            className="rounded-xl shadow-xl"
            src="https://www.youtube.com/embed/cBQtS5DG_cY"
            allowFullScreen
          />
        </div>
      ),
      skills: ["React Native", "React", "Javascript", "Python", "Git", "Google Vision", "Node JS", "HTML", "CSS"],
    },
    {
      title: "RiNTER",
      description: `RiNTER is a real estate app that allows tenant and owners to more easily connect, transfer payments, track
      maintenance and works orders, and store important documents all in the same place. Developed for United
      Property Group`,
      item: () => <img src={rinter} className="rounded-xl w-3/4 shadow-xl" alt="" />,
      skills: ["React Native", "Figma", "Javascript", "Git"],
    },
    {
      title: "Speech Recognition Matching",
      description: `Developed matching algorithms to recognize speaker intent in short spoken phrases`,
      skills: ["Javascript", "Swift", "Apple Speech Recognition", "Microsoft Azure Speech to Text", "Git"],
    },
    {
      title: "BestLife",
      description: `The winning project of the Georgia Tech Greek Week Hackathon, BestLife aims to help individuals track a
      large variety of factors (stress, physical activity, social activity, nutrition, etc.) on a weekly basis. 
      Then, users can visualize these habits in a HeatMap across the number of weeks they have submitted the form. 
      Our goal with this app is for users to be able to recognize when their habits are good and when they start to suffer.
      From there, users can self-diagnose what may have led to the trends shown in the heat map`,
      skills: [
        "Javascript",
        "Swift ",
        "Apple Speech Recognition",
        "Microsoft Azure Speech to Text",
        "HTML",
        "CSS",
        "Git",
      ],
    },
    {
      title: "AI Baseball Sign Predictor",
      description: `Developed a tool to predict the meaning of signs of baseball teams. The user inputs the sequence of signs from 
      the opposing team's coach and the outcome of the play. The specially designed neural network learns from this data and can 
      predict the opposing team's signs with a high degree of accuracy`,
      skills: ["Python", "TensorFlow", "Neural Networks"],
    },
    {
      title: "State Legislation Web Scraper",
      description: `Built a tool for education software company Abre to scrape data from the state legislature sites of Ohio, Indiana, 
      Kentucky, Montana, Texas, Illinois, and Florida. These states make inconsistent updates to their education standards and expect 
      schools to follow these updates without easily being notified. This tool provides a way for schools to easily view information about 
      standards updates pulled from complex and lengthy legislation documents`,
      skills: ["Puppeteer", "Node JS", "Express", "Javascript", "Git"],
    },
    {
      title: "BuzzCard Activated Lock",
      description: `Built a mechanism that attaches to a door of a dorm/apartment and uses an RFID reader to detect specified BuzzCards
       and unlock the door, eliminating the need to carry a set of keys`,
      item: () => (
        <div>
          <iframe
            title="RFID"
            className="rounded-xl flex-1 shadow-xl"
            src="https://www.youtube.com/embed/DD79V9Ay_74?mute=1"
            allowFullScreen
            muted
          />
        </div>
      ),
      skills: ["Arduino", "RFID Read/Write", "3D Modeling", "3D Printing", "Servo Motors"],
    },
    {
      title: "and many more...",
      skills: [
        "MongoDB",
        "Realm",
        "iOS Development/XCode",
        "Video Streaming",
        "MacOS",
        "Linux",
        "AWS S3",
        "AWS Amplify",
        "AWS DynamoDB",
        "Unity Game Design",
        "Firebase",
        "Leadership",
        "Management",
      ],
    },
  ];

  const projectsBody = () => (
    <div className="flex flex-wrap gap-10">
      {projects.map((project) => (
        <div key={project.title} className="flex-1 flex flex-col gap-5" style={{ minWidth: 300 }}>
          <p className="text-4xl font-bold">{project.title}</p>
          {project.description ? <p>{project.description}</p> : null}
          {project.item ? project.item() : null}
          <div className="flex gap-2 items-start flex-wrap">
            {project.skills
              ? project.skills.map((skill) => {
                  const color = colors[Math.floor(Math.random() * colors.length)];
                  return <Badge key={skill} text={skill} color={color} />;
                })
              : null}
          </div>
        </div>
      ))}
    </div>
  );

  const aboutMeBody = () => (
    <div className="flex flex-col gap-5">
      <p>
        I'm just a guy from Fort Thomas, KY (near Cincinatti, OH) who likes sports, solving problems, and having fun! I
        originally got into programming when I was 11 by making mini-games and moved on to more and more complex
        projects over the years. I now go to Georgia Tech and study Computer Engineering. I played baseball and
        basketball in highschool. I'm now on the Spikeball team here at Georgia Tech and have played in the Spikeball
        college national tournament. I also love to do almost anything there is to do outdoors! I currently work on the
        development team at Kleo, a language learning software company that is redefining the way people can learn
        languages that we hope to launch soon. Here's some pictures for your enjoyment...
      </p>
      <div className="self-center mt-10" style={{ minWidth: 250, width: "40%" }}>
        <AwesomeSlider>
          <div className="h-full w-full">
            <img src={fish} className="object-contain w-full h-full" alt="" />
          </div>
          <div className="h-full w-full">
            <img src={rafting1} className="object-contain w-full h-full" alt="" />
          </div>
          <div className="h-full w-full">
            <img src={rafting2} className="object-contain w-full h-full" alt="" />
          </div>
          <div className="h-full w-full">
            <img src={savage1} className="object-contain w-full h-full" alt="" />
          </div>
          <div className="h-full w-full">
            <img src={savage2} className="object-contain w-full h-full" alt="" />
          </div>
          <div className="h-full w-full">
            <img src={savage3} className="object-contain w-full h-full" alt="" />
          </div>
          <div className="h-full w-full">
            <img src={nate} className="object-contain w-full h-full" alt="" />
          </div>
          <div className="h-full w-full">
            <img src={spikeball} className="object-contain w-full h-full" alt="" />
          </div>
          <div className="h-full w-full">
            <img src={maddy} className="object-contain w-full h-full" alt="" />
          </div>
        </AwesomeSlider>
      </div>
    </div>
  );

  const contactBody = () => (
    <div className="flex flex-col gap-5">
      <SocialIcons darkMode={props.darkMode} />
      <div>
        <p className="text-xl font-bold">Email</p>
        <p>weidnerluke@gmail.com</p>
      </div>
      <div>
        <p className="text-xl font-bold">Phone</p>
        <p>(513)-446-9378</p>
      </div>
    </div>
  );

  const sections = [
    {
      title: "About Me",
      body: aboutMeBody,
    },
    {
      title: "Projects",
      body: projectsBody,
    },
    {
      title: "Contact",
      body: contactBody,
    },
  ];

  return (
    <div className="w-full">
      <div className="flex">
        <div className="w-1/2 flex flex-col gap-20 p-20">
          <div className="flex-1 flex flex-col gap-5">
            <p className="text-7xl font-black">Hi, I'm Luke,</p>
            <p className="text-5xl font-black">Luke Weidner</p>
            <p className="text-lg">
              I am Chief Technology Officer at Kleo Language Learning and a first year Computer Engineering student at
              Georgia Tech
            </p>
          </div>

          <div className="flex-1 flex items-center justify-center">
            <ChevronDoubleDownIcon className="w-1/4 text-gray-400 animate-bounce" />
          </div>
        </div>
        <div
          className="flex flex-col items-end justify-end overflow-hidden"
          style={{
            background: "linear-gradient(90deg, rgba(0,204,255,1), rgba(99,102,241,1))",
            borderBottomLeftRadius: 999,
            width: window.screen.width / 2,
            height: window.screen.width / 2,
          }}
        >
          <img className="w-5/6" style={{ transform: "scaleX(-1)" }} alt="" />
        </div>
      </div>
      {sections.map((section) => (
        <div key={section.title} className="flex flex-col gap-5 mt-20 p-20">
          <p className="text-7xl font-black">{section.title}</p>
          <div className="w-full bg-gray-200 dark:bg-gray-600 h-0.5" />
          {section.body()}
        </div>
      ))}
    </div>
  );
}
