import { useHistory } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/solid";

import { Button } from "../components/base/components";

export default function NotFoundPage(props) {
  const history = useHistory();

  return (
    <div className="p-20 flex flex-col gap-5 items-start">
      <p className="text-7xl font-black">Sorry!</p>
      <p className="sm:text-5xl font-black">This page doesn't exist</p>
      <Button className="mt-10" onClick={() => history.push("/")}>
        <HomeIcon className="w-4 h-4" />
        Go Home
      </Button>
    </div>
  );
}
