import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faSnapchat, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

import { Button } from "../base/components";

export default function SocialIcons(props) {
  return (
    <div className="flex gap-2">
      <Button
        shade={props.darkMode ? 600 : 100}
        onClick={() => (window.location.href = "https://www.linkedin.com/in/luke-weidner-a1880019a/")}
      >
        <FontAwesomeIcon size="lg" style={{ width: "1rem" }} icon={faLinkedin} />
      </Button>
      <Button shade={props.darkMode ? 600 : 100} onClick={() => (window.location.href = "https://github.com/Luke20w")}>
        <FontAwesomeIcon size="lg" style={{ width: "1rem" }} icon={faGithub} />
      </Button>
      <Button
        shade={props.darkMode ? 600 : 100}
        onClick={() => (window.location.href = "https://www.instagram.com/luke20w")}
      >
        <FontAwesomeIcon size="lg" style={{ width: "1rem" }} icon={faInstagram} />
      </Button>
      <Button
        shade={props.darkMode ? 600 : 100}
        onClick={() => (window.location.href = "https://www.snapchat.com/add/luke20w")}
      >
        <FontAwesomeIcon size="lg" style={{ width: "1rem" }} icon={faSnapchat} />
      </Button>
    </div>
  );
}
