import { useLocation, useHistory } from "react-router-dom";
import { MoonIcon, SunIcon } from "@heroicons/react/solid";

import { Button } from "../base/components";
import { SocialIcons } from "../custom/components";

export default function Header(props) {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="sticky top-0 w-full px-5 py-3 bg-white dark:bg-gray-900 shadow-xl z-10 justify-between flex items-center">
      <div className="flex gap-5">
        {props.pages
          ? Object.keys(props.pages).map((key) => (
              <Button
                key={key}
                type="text"
                selected={location.pathname === props.pages[key]}
                onClick={() =>
                  typeof props.pages[key] == "string"
                    ? history.push(props.pages[key])
                    : window.scrollTo({
                        top: props.pages[key],
                        left: 0,
                        behavior: "smooth",
                      })
                }
              >
                {key}
              </Button>
            ))
          : null}
      </div>
      <div className="flex gap-5">
        <SocialIcons darkMode={props.darkMode} />
        {props.darkMode ? (
          <Button shade={100} onClick={() => props.setDarkMode(false)}>
            <SunIcon className="w-4 h-4" />
          </Button>
        ) : (
          <Button onClick={() => props.setDarkMode(true)}>
            <MoonIcon className="w-4 h-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
